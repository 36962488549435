<template>
  <div class="email-tbl content-container h-100" v-if="message">
    <div class=" p-0">
      <div class="subject-title mb-3 d-flex">
        <img src="../../../assets/images/blog-dark.svg">
        <h1 class="mb-0 ms-3 mt-1 ml-2 text-capitalize">{{sentUserLists.emailLog.email_subject}}</h1>
      </div>
      <div class="email-send-detail">
        <div class="d-flex">
          <div class="user-img">
            <img
                src="../../../assets/images/default_profile.jpg"
                alt="User Picture"
            />
          </div>
          <div class="notify-section mt-2">
            <h5 class="mb-1">{{sentUserLists.emailLog.from_name}}</h5>
            <p class="send-to">From : {{sentUserLists.emailLog.from_email}}</p>
          </div>
        </div>
        <div class="receive-date">{{sentUserLists.emailLog.sent_date}}</div>
      </div>
      <div>
        <div class="email-template-container" v-html="sentUserLists.contentTemplate" style="width: 100% !important;"></div>
      </div>
      <div class="mt-4">
        <h6>Attachments:</h6>
        <!-- File attachment section if any-->
        <div class="attach-file" v-if="sentUserLists.emailLog.email_log_attachments.length > 0">
          <ul style="list-style: none">
            <li v-for="file in sentUserLists.emailLog.email_log_attachments" :key="file.id">
              <a :href="file.file_url" target="__blank" download>
                <i class="fa fa-file"></i>
                {{ file.title }}
              </a>
            </li>
          </ul>
        </div>
        <div v-else>
          Attachment had not been added.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "NueraBlogsContent",
  props: {
    message: {
      required: true
    }
  },
  data: () => ({
    sentUserLists: {},
  }),
  methods: {
    getSentUserLists() {
      let loader = this.$loading.show();
      const url = `${process.env.VUE_APP_CORENROLL_SYSTEM_API_V2}blog/detail/${this.message.id}`
      axios.get(url)
          .then((res) => {
            this.sentUserLists = res.data.data;
          }) .catch(() => {
            this.sentUserLists = {}
         })
          .finally(() => {
            loader.hide();
          });
    },
  },
  created() {
    this.getSentUserLists();
  },
  watch: {
    message: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.getSentUserLists();
      }
    },
  },
}
</script>

<style>
.email-template-container {
  padding: 1.5rem;
  background-color: #eee;
}
.email-template-container img {
  width: 100% !important;
  margin-top: 10px;
}
</style>
