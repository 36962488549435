var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "member-body-content-wrap min-height pt-3"
  }, [_c('div', [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "email-tbl h-100"
  }, [_c('div', {
    staticClass: "search-wrap mb-3"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    staticClass: "search-input",
    attrs: {
      "placeholder": "Search "
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchQuery = $event.target.value;
      }
    }
  }), _c('button', {
    on: {
      "click": _vm.searchBlogList
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])]), _c('div', [_c('nuera-message-list', {
    attrs: {
      "rows": _vm.rows
    },
    on: {
      "onClickedItem": _vm.getMessage,
      "update:rows": function ($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center p-3 pb-0"
  }, [_c('div', {
    staticClass: "mt-3"
  }, [_c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "showPageRecord": false
    },
    on: {
      "update:rows": function ($event) {
        _vm.rows = $event;
      }
    }
  })], 1)])])]), _vm.showMessage ? _c('div', {
    staticClass: "col-md-8"
  }, [_c('nuera-blogs-content', {
    attrs: {
      "message": _vm.showMessage
    }
  })], 1) : _vm._e()])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-end"
  }, [_c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Pure Wellness Blogs")])])]);

}]

export { render, staticRenderFns }